export const CatalogIconFilter = () => {
    return (
        <svg
            width="31"
            height="31"
            viewBox="0 0 31 31"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_16_7049)">
                <path
                    d="M15.9658 17.4685V0H14.0875V17.4685C11.9274 17.8911 10.3308 19.8164 10.3308 22.0704C10.3308 24.3244 11.9274 26.2497 14.0875 26.6723V30.0533H15.9658V26.6723C18.1259 26.2497 19.7225 24.3244 19.7225 22.0704C19.7225 19.8164 18.1259 17.8911 15.9658 17.4685ZM15.0266 24.8879C13.477 24.8879 12.2091 23.62 12.2091 22.0704C12.2091 20.5208 13.477 19.2529 15.0266 19.2529C16.5763 19.2529 17.8441 20.5208 17.8441 22.0704C17.8441 23.62 16.5763 24.8879 15.0266 24.8879Z"
                    fill="#808080"
                />
                <path
                    d="M29.1141 11.7396C29.1141 9.48558 27.5175 7.56029 25.3574 7.13767V0H23.4791V7.13767C21.319 7.56029 19.7224 9.48558 19.7224 11.7396C19.7224 13.9936 21.319 15.9189 23.4791 16.3415V30.0533H25.3574V16.3415C27.5175 15.9189 29.1141 13.9936 29.1141 11.7396ZM24.4182 14.5571C22.8686 14.5571 21.6007 13.2892 21.6007 11.7396C21.6007 10.19 22.8686 8.92208 24.4182 8.92208C25.9679 8.92208 27.2357 10.19 27.2357 11.7396C27.2357 13.2892 25.9679 14.5571 24.4182 14.5571Z"
                    fill="#808080"
                />
                <path
                    d="M10.3309 8.4525C10.3309 6.1985 8.73429 4.27321 6.57421 3.85058V0H4.69588V3.85058C2.53579 4.27321 0.939209 6.1985 0.939209 8.4525C0.939209 10.7065 2.53579 12.6318 4.69588 13.0544V30.0533H6.57421V13.0544C8.73429 12.6318 10.3309 10.7065 10.3309 8.4525ZM5.63504 11.27C4.08542 11.27 2.81754 10.0021 2.81754 8.4525C2.81754 6.90288 4.08542 5.635 5.63504 5.635C7.18467 5.635 8.45254 6.90288 8.45254 8.4525C8.45254 10.0021 7.18467 11.27 5.63504 11.27Z"
                    fill="#808080"
                />
            </g>
            <defs>
                <clipPath id="clip0_16_7049">
                    <rect width="30.0533" height="30.0533" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

export const CatalogIconSort = () => {
    return (
        <svg
            width="29"
            height="28"
            viewBox="0 0 29 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M27.2039 23.333H15.5505C15.0144 23.333 14.5794 23.7681 14.5794 24.3051C14.5794 24.8421 15.0144 25.2772 15.5505 25.2772L27.2039 25.249C27.74 25.249 28.1751 24.8421 28.1751 24.3051C28.175 23.7681 27.741 23.333 27.2039 23.333ZM13.6083 1.94419L27.2039 1.94226C27.74 1.94226 28.1751 1.50915 28.1751 0.972096C28.1751 0.43504 27.74 0 27.2039 0H13.6083C13.0722 0 12.6372 0.43504 12.6372 0.972096C12.6372 1.50915 13.0722 1.94419 13.6083 1.94419ZM15.2466 17.7744C14.8659 17.3917 14.2483 17.3917 13.8676 17.7744L8.75271 23.6603V0H6.8105V23.6952L1.6645 17.7744C1.28383 17.3917 0.666164 17.3917 0.285538 17.7744C-0.0951794 18.157 -0.0951794 18.7766 0.285538 19.1582L7.02701 26.9165C7.22998 27.1204 7.49993 27.2068 7.76505 27.1932C8.0311 27.2068 8.30013 27.1204 8.50306 26.9165L15.2446 19.1582C15.6273 18.7766 15.6273 18.157 15.2466 17.7744ZM27.2039 5.8335H13.6083C13.0722 5.8335 12.6372 6.26858 12.6372 6.80559C12.6372 7.34265 13.0722 7.76894 13.6083 7.76894H27.2039C27.74 7.76894 28.1751 7.3426 28.1751 6.80559C28.175 6.26858 27.741 5.8335 27.2039 5.8335ZM27.2039 11.666H13.6083C13.0722 11.666 12.6372 12.1011 12.6372 12.6381C12.6372 13.1752 13.0722 13.5947 13.6083 13.5947L27.2039 13.6102C27.74 13.6102 28.1751 13.1751 28.1751 12.6381C28.175 12.1021 27.741 11.666 27.2039 11.666ZM27.2039 17.4995H19.435C18.8989 17.4995 18.4639 17.9346 18.4639 18.4716C18.4639 19.0086 18.8989 19.4223 19.435 19.4223H27.2039C27.74 19.4223 28.1751 19.0086 28.1751 18.4716C28.175 17.9346 27.741 17.4995 27.2039 17.4995Z"
                fill="#808080"
            />
        </svg>
    )
}

export const CatalogIconChevron = () => {
    return (
        <svg
            width="11"
            height="6"
            viewBox="0 0 11 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M9.04143 0.292893L5.74854 3.58579L2.45564 0.292893C2.06512 -0.097631 1.43195 -0.097631 1.04143 0.292893C0.650904 0.683418 0.650904 1.31658 1.04143 1.70711L5.04143 5.70711C5.43195 6.09763 6.06512 6.09763 6.45564 5.70711L10.4556 1.70711C10.8462 1.31658 10.8462 0.683418 10.4556 0.292893C10.0651 -0.097631 9.43195 -0.097631 9.04143 0.292893Z"
                fill="#1D1D1D"
                fillOpacity="0.1"
            />
        </svg>
    )
}

export const CatalogIconCircle = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="12" cy="12" r="12" fill="#D9D9D9" />
        </svg>
    )
}

export const CatalogIconConception = () => {
    return (
        <svg
            width="28.000000"
            height="23.757812"
            viewBox="0 0 28 23.7578"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <desc>Created with Pixso.</desc>
            <defs>
                <clipPath id="clip9_4206">
                    <rect
                        id="Swag Automation Easily Send Gifts with our Swag API and Integrati (3)"
                        width="28.000000"
                        height="23.757582"
                        fill="white"
                        fillOpacity="0"
                    />
                </clipPath>
                <linearGradient
                    x1="-0.000002"
                    y1="0.294773"
                    x2="19.049204"
                    y2="23.581812"
                    id="paint_linear_9_4198_0"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#4AFF0B" />
                    <stop offset="1.000000" stopColor="#00E5FF" />
                </linearGradient>
                <linearGradient
                    x1="15.996824"
                    y1="7.195331"
                    x2="27.713369"
                    y2="19.231861"
                    id="paint_linear_9_4199_0"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#4AFF0B" />
                    <stop offset="1.000000" stopColor="#00E5FF" />
                </linearGradient>
                <linearGradient
                    x1="2.713131"
                    y1="3.068153"
                    x2="16.336479"
                    y2="6.060455"
                    id="paint_linear_9_4200_0"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#4AFF0B" />
                    <stop offset="1.000000" stopColor="#00E5FF" />
                </linearGradient>
                <linearGradient
                    x1="2.713133"
                    y1="7.958786"
                    x2="16.336481"
                    y2="10.951080"
                    id="paint_linear_9_4201_0"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#4AFF0B" />
                    <stop offset="1.000000" stopColor="#00E5FF" />
                </linearGradient>
                <linearGradient
                    x1="2.741942"
                    y1="12.848415"
                    x2="16.365314"
                    y2="15.840740"
                    id="paint_linear_9_4202_0"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#4AFF0B" />
                    <stop offset="1.000000" stopColor="#00E5FF" />
                </linearGradient>
                <linearGradient
                    x1="3.091796"
                    y1="17.622309"
                    x2="6.300813"
                    y2="20.881414"
                    id="paint_linear_9_4203_0"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#4AFF0B" />
                    <stop offset="1.000000" stopColor="#00E5FF" />
                </linearGradient>
                <linearGradient
                    x1="7.788818"
                    y1="17.622309"
                    x2="10.997828"
                    y2="20.881414"
                    id="paint_linear_9_4204_0"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#4AFF0B" />
                    <stop offset="1.000000" stopColor="#00E5FF" />
                </linearGradient>
                <linearGradient
                    x1="12.777100"
                    y1="17.622309"
                    x2="15.431778"
                    y2="20.881414"
                    id="paint_linear_9_4205_0"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#4AFF0B" />
                    <stop offset="1.000000" stopColor="#00E5FF" />
                </linearGradient>
            </defs>
            <rect
                id="Swag Automation Easily Send Gifts with our Swag API and Integrati (3)"
                width="28.000000"
                height="23.757582"
                fill="#FFFFFF"
                fillOpacity="0"
            />
            <g clipPath="url(#clip9_4206)">
                <path
                    id="Vector"
                    d="M18.52 23.58L0.52 23.58C0.23 23.58 0 23.34 0 23.04L0 0.54C0 0.24 0.23 0 0.52 0L18.52 0C18.81 0 19.04 0.24 19.04 0.54L19.04 11.79C19.04 12.09 18.81 12.33 18.52 12.33C18.23 12.33 17.99 12.09 17.99 11.79L17.99 1.11L1.07 1.11L1.07 22.5L17.99 22.5L17.99 20.79C17.99 20.49 18.23 20.25 18.52 20.25C18.81 20.25 19.04 20.49 19.04 20.79L19.04 23.04C19.04 23.34 18.81 23.58 18.52 23.58Z"
                    fill="url(#paint_linear_9_4198_0)"
                    fillOpacity="1.000000"
                    fillRule="nonzero"
                />
                <path
                    id="Vector"
                    d="M16.54 19.23C16.39 19.23 16.27 19.17 16.16 19.08C15.98 18.9 15.95 18.66 16.04 18.45L17.21 16.14C17.24 16.08 17.26 16.05 17.29 15.99L25.72 7.2C25.93 6.99 26.25 6.99 26.48 7.2L27.53 8.25C27.65 8.34 27.71 8.49 27.71 8.64C27.71 8.79 27.65 8.94 27.56 9.03L19.1 17.82C19.07 17.85 19.02 17.88 18.99 17.91L16.77 19.17C16.71 19.23 16.62 19.23 16.54 19.23ZM18.11 16.71L17.79 17.34L18.4 16.98L26.4 8.64L26.1 8.34L18.11 16.71Z"
                    fill="url(#paint_linear_9_4199_0)"
                    fillOpacity="1.000000"
                    fillRule="nonzero"
                />
                <path
                    id="Vector"
                    d="M15.92 6.06L3.12 6.06C2.88 6.06 2.71 5.88 2.71 5.64L2.71 3.45C2.71 3.2 2.88 3.03 3.12 3.03L15.92 3.03C16.16 3.03 16.33 3.2 16.33 3.45L16.33 5.64C16.33 5.88 16.16 6.06 15.92 6.06ZM3.53 5.25L15.51 5.25L15.51 3.87L3.53 3.87L3.53 5.25Z"
                    fill="url(#paint_linear_9_4200_0)"
                    fillOpacity="1.000000"
                    fillRule="nonzero"
                />
                <path
                    id="Vector"
                    d="M15.92 10.95L3.12 10.95C2.88 10.95 2.71 10.77 2.71 10.53L2.71 8.34C2.71 8.1 2.88 7.92 3.12 7.92L15.92 7.92C16.16 7.92 16.33 8.1 16.33 8.34L16.33 10.53C16.33 10.77 16.16 10.95 15.92 10.95ZM3.53 10.14L15.51 10.14L15.51 8.76L3.53 8.76L3.53 10.14Z"
                    fill="url(#paint_linear_9_4201_0)"
                    fillOpacity="1.000000"
                    fillRule="nonzero"
                />
                <path
                    id="Vector"
                    d="M15.95 15.84L3.15 15.84C2.91 15.84 2.74 15.66 2.74 15.42L2.74 13.23C2.74 12.99 2.91 12.81 3.15 12.81L15.95 12.81C16.19 12.81 16.36 12.99 16.36 13.23L16.36 15.42C16.36 15.66 16.16 15.84 15.95 15.84ZM3.55 15.03L15.54 15.03L15.54 13.65L3.55 13.65L3.55 15.03Z"
                    fill="url(#paint_linear_9_4202_0)"
                    fillOpacity="1.000000"
                    fillRule="nonzero"
                />
                <path
                    id="Vector"
                    d="M5.89 20.88L3.5 20.88C3.26 20.88 3.09 20.7 3.09 20.46L3.09 18C3.09 17.76 3.26 17.58 3.5 17.58L5.89 17.58C6.12 17.58 6.3 17.76 6.3 18L6.3 20.46C6.27 20.7 6.09 20.88 5.89 20.88ZM3.87 20.07L5.48 20.07L5.48 18.42L3.87 18.42L3.87 20.07Z"
                    fill="url(#paint_linear_9_4203_0)"
                    fillOpacity="1.000000"
                    fillRule="nonzero"
                />
                <path
                    id="Vector"
                    d="M10.58 20.88L8.19 20.88C7.96 20.88 7.78 20.7 7.78 20.46L7.78 18C7.78 17.76 7.96 17.58 8.19 17.58L10.58 17.58C10.82 17.58 10.99 17.76 10.99 18L10.99 20.46C10.99 20.7 10.79 20.88 10.58 20.88ZM8.57 20.07L10.18 20.07L10.18 18.42L8.57 18.42L8.57 20.07Z"
                    fill="url(#paint_linear_9_4204_0)"
                    fillOpacity="1.000000"
                    fillRule="nonzero"
                />
                <path
                    id="Vector"
                    d="M15.02 20.88L13.18 20.88C12.95 20.88 12.77 20.7 12.77 20.46L12.77 18C12.77 17.76 12.95 17.58 13.18 17.58L14.99 17.58C15.22 17.58 15.4 17.76 15.4 18C15.4 18.24 15.22 18.42 14.99 18.42L13.56 18.42L13.56 20.07L15.02 20.07C15.25 20.07 15.43 20.25 15.43 20.49C15.43 20.73 15.22 20.88 15.02 20.88Z"
                    fill="url(#paint_linear_9_4205_0)"
                    fillOpacity="1.000000"
                    fillRule="nonzero"
                />
            </g>
        </svg>
    )
}
