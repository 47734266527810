export const HeaderIconBasket = () => {
    return (
        <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.0281 4.45463C22.6985 4.45463 23.3266 4.75197 23.751 5.26982C24.1753 5.78767 24.3434 6.46144 24.212 7.11849L22.4302 16.0277C22.223 17.0646 21.3042 17.8185 20.2463 17.8185H16.7584C18.5757 17.8473 20.0456 19.335 20.0456 21.159C20.0456 23.001 18.5466 24.5 16.7046 24.5C14.8627 24.5 13.3637 23.001 13.3637 21.159C13.3637 19.335 14.8336 17.8473 16.6509 17.8185H10.0764C11.8937 17.8473 13.3637 19.335 13.3637 21.159C13.3637 23.001 11.8647 24.5 10.0227 24.5C8.18072 24.5 6.68174 23.001 6.68174 21.159C6.68174 19.335 8.15167 17.8473 9.96899 17.8185H6.53382C5.43242 17.8185 4.48469 16.9955 4.3299 15.9052L2.5113 3.18283C2.43334 2.63825 1.96004 2.22731 1.40878 2.22731H1.11366C0.498918 2.22731 0 1.7284 0 1.11366C0 0.497805 0.498918 0 1.11366 0H1.40878C3.06144 0 4.48247 1.23282 4.71634 2.86878L6.53382 15.5912H20.2463L22.0281 6.68194H8.90925C8.29451 6.68194 7.7956 6.18302 7.7956 5.56828C7.7956 4.95243 8.29451 4.45463 8.90925 4.45463H22.0281ZM10.0228 22.2727C9.40918 22.2727 8.90915 21.7727 8.90915 21.1591C8.90915 20.5454 9.40918 20.0454 10.0228 20.0454C10.6364 20.0454 11.1365 20.5454 11.1365 21.1591C11.1365 21.7727 10.6364 22.2727 10.0228 22.2727ZM16.7048 20.0454C17.3184 20.0454 17.8184 20.5454 17.8184 21.1591C17.8184 21.7727 17.3184 22.2727 16.7048 22.2727C16.0911 22.2727 15.5911 21.7727 15.5911 21.1591C15.5911 20.5454 16.0911 20.0454 16.7048 20.0454Z"
                fill="#F1F1F1"
            />
        </svg>
    )
}

export const HeaderIconUser = () => {
    return (
        <svg
            width="25"
            height="26"
            viewBox="0 0 25 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.25 14.1136C8.55978 14.1136 5.56823 11.1221 5.56823 7.43182C5.56823 3.74155 8.55978 0.75 12.25 0.75C15.9403 0.75 18.9319 3.74155 18.9319 7.43182C18.9319 11.1221 15.9403 14.1136 12.25 14.1136ZM16.7045 16.3409H7.79545C3.49014 16.3409 0 19.831 0 24.1363C0 24.7514 0.498592 25.25 1.11364 25.25H23.3864C24.0014 25.25 24.5 24.7514 24.5 24.1363C24.5 19.831 21.0099 16.3409 16.7045 16.3409ZM7.79554 18.5682H16.7046C19.3985 18.5682 21.6455 20.4811 22.1614 23.0227H2.33875C2.85466 20.4811 5.1017 18.5682 7.79554 18.5682ZM16.7047 7.43182C16.7047 9.892 14.7103 11.8864 12.2501 11.8864C9.78994 11.8864 7.79557 9.892 7.79557 7.43182C7.79557 4.97165 9.78994 2.97728 12.2501 2.97728C14.7103 2.97728 16.7047 4.97165 16.7047 7.43182Z"
                fill="#F1F1F1"
            />
        </svg>
    )
}

export const HeaderIconLike = () => {
    return (
        <svg
            width="23"
            height="19"
            viewBox="0 0 23 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12.3297 1.80635C12.0071 2.12558 11.7246 2.47406 11.4844 2.84503C11.2441 2.47406 10.9617 2.12558 10.6391 1.80635C8.20527 -0.602118 4.26348 -0.602118 1.82966 1.80635C-0.609887 4.22049 -0.609887 8.13889 1.82966 10.553C2.54181 11.2577 5.52291 13.8376 10.8471 18.3597C11.2146 18.6718 11.7542 18.6718 12.1216 18.3596C17.4488 13.8348 20.4297 11.255 21.1391 10.553C23.5786 8.13889 23.5786 4.22049 21.1391 1.80635C18.7053 -0.602118 14.7635 -0.602118 12.3297 1.80635ZM19.7543 3.20574C21.4152 4.8494 21.4152 7.50998 19.7543 9.15364C19.1265 9.77486 16.348 12.1827 11.4843 16.3176C6.62329 12.1852 3.84487 9.77747 3.21447 9.15364C1.55351 7.50998 1.55351 4.8494 3.21447 3.20574C4.88115 1.55642 7.5876 1.55642 9.25428 3.20574C10.0635 4.00653 10.4997 5.07061 10.5 6.17861L12.4688 6.17861C12.469 5.07061 12.9052 4.00653 13.7145 3.20574C15.3811 1.55642 18.0876 1.55642 19.7543 3.20574Z"
                fill="#F1F1F1"
            />
        </svg>
    )
}

export const HeaderIconSearch = () => {
    return (
        <svg
            width="21"
            height="21"
            viewBox="0 0 21 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9C18 10.9424 17.3847 12.741 16.3383 14.2117L20.5606 18.4393C21.1464 19.0251 21.1464 19.9749 20.5606 20.5607C19.9748 21.1465 19.0251 21.1465 18.4393 20.5607L14.2179 16.3339C12.7461 17.3829 10.9451 18 9 18ZM9 15C12.3137 15 15 12.3137 15 9C15 5.68629 12.3137 3 9 3C5.68629 3 3 5.68629 3 9C3 12.3137 5.68629 15 9 15Z"
                fill="#F1F1F1"
            />
        </svg>
    )
}

export const HeaderIconMicrofon = () => {
    return (
        <svg
            width="14"
            height="20"
            viewBox="0 0 14 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M10 3C10 1.34315 8.65685 0 7 0C5.34315 0 4 1.34315 4 3V9C4 10.6569 5.34315 12 7 12C8.65685 12 10 10.6569 10 9V3ZM8 19V15.9291C11.3923 15.4439 14 12.5265 14 9C14 8.44771 13.5523 8 13 8C12.4477 8 12 8.44771 12 9C12 11.7614 9.76142 14 7 14C4.23858 14 2 11.7614 2 9C2 8.44771 1.55228 8 1 8C0.447715 8 0 8.44771 0 9C0 12.5265 2.60771 15.4439 6 15.9291V19C6 19.5523 6.44772 20 7 20C7.55228 20 8 19.5523 8 19Z"
                fill="#1E1E1E"
                fillOpacity="0.48"
            />
        </svg>
    )
}


export const HeaderIconArrow = () => {
  return (
<svg width="30" height="26" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="2.10593" height="17.2574" rx="0.833333" transform="matrix(0 1 1 0 6.57886 11.7493)" fill="#F1F1F1" />
  <path d="M14.5275 6.96436C14.0461 6.55316 14.0461 5.88645 14.5275 5.47525C15.0089 5.06404 15.7894 5.06404 16.2708 5.47525L23.6668 11.793C24.1335 12.1917 24.1498 12.8335 23.7038 13.2491L16.9241 19.5669C16.4641 19.9956 15.6844 20.0245 15.1825 19.6316C14.6807 19.2386 14.6468 18.5726 15.1068 18.1439L21.089 12.5693L14.5275 6.96436Z" fill="#F1F1F1" />
</svg>
  )
}


export const HeaderIconReload = () => {
  return (
    <svg width="34" height="30" viewBox="0 0 34 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M5.0481 13.9138C5.40524 10.0259 7.60565 6.56742 11 4.60772C16.1375 1.64154 22.5594 2.90569 26.2263 7.32392L22.0044 6.19266L21.2279 9.09044L30.6457 11.6139L33.1692 2.19615L30.2714 1.41969L29.0329 6.04175C24.5531 0.00856863 16.1632 -1.83739 9.49995 2.00964C5.25932 4.45797 2.50667 8.78417 2.06067 13.6394L1.99207 14.3862L4.97949 14.6607L5.0481 13.9138ZM28.9723 14.1421C29.2966 18.7206 26.9926 23.0872 23 25.3923C17.4141 28.6173 10.3132 26.841 6.88234 21.4529L11.4346 22.6726L12.211 19.7748L2.79326 17.2514L0.269775 26.6691L3.16755 27.4456L4.34457 23.0529C8.62993 29.7984 17.5131 32.0243 24.5 27.9904C29.4895 25.1097 32.37 19.6507 31.9648 13.9301L31.9118 13.182L28.9193 13.3939L28.9723 14.1421Z" fill="url(#paint0_linear_18_2738)" />
    <defs>
      <linearGradient id="paint0_linear_18_2738" x1="0.269775" y1="0.372621" x2="30.1825" y2="32.7609" gradientUnits="userSpaceOnUse">
        <stop stopColor="#4AFF0B" />
        <stop offset="1" stopColor="#00E5FF" />
      </linearGradient>
    </defs>
  </svg>
  )
}



export const HeaderIconBurger = () => {
  return (
    <svg width="40" height="24" viewBox="0 0 40 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5 6H35" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5 18H35" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5 12L35 12" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    
  )
}



export const HeaderIconClose = () => {
  return (
    <svg width="24.000000" height="24.000000" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
	<desc>
			Created with Pixso.
	</desc>
	<defs/>
	<g opacity="0.600000">
		<path id="Path" d="M12 13.53L22.31 23.71C22.7 24.09 23.32 24.09 23.71 23.71C24.09 23.33 24.09 22.71 23.71 22.33L13.24 12L23.71 1.66C24.09 1.28 24.09 0.66 23.71 0.28C23.32 -0.1 22.7 -0.1 22.31 0.28L12 10.46L1.68 0.28C1.29 -0.1 0.67 -0.1 0.28 0.28C-0.1 0.66 -0.1 1.28 0.28 1.66L10.75 12L0.28 22.33C-0.1 22.71 -0.1 23.33 0.28 23.71C0.67 24.09 1.29 24.09 1.68 23.71L12 13.53Z" fill="#FFFFFF" fillOpacity="1.000000" fillRule="evenodd"/>
	</g>
</svg>

  )
}



export const HeaderIconOpen = () => {
  return (
    <svg width="38.333374" height="30.000000" viewBox="0 0 38.3334 30" fill="none" xmlns="http://www.w3.org/2000/svg" >
	<desc>
			Created with Pixso.
	</desc>
	<defs>
		<clipPath id="clip343_14026">
			<rect id="Contact Us - Swag.com (4)" width="38.333332" height="30.000000" fill="white" fillOpacity="0"/>
		</clipPath>
		<linearGradient x1="-0.000004" y1="15.072632" x2="38.333324" y2="29.837296" id="paint_linear_9_2338_0" gradientUnits="userSpaceOnUse">
			<stop stopColor="#4AFF0B"/>
			<stop offset="1.000000" stopColor="#00E5FF"/>
		</linearGradient>
	</defs>
	<rect id="Contact Us - Swag.com (4)" width="38.333332" height="30.000000" fill="#FFFFFF" fillOpacity="0"/>
	<g clipPath="url(#clip343_14026)">
		<path id="Vector" d="M11.14 16.08C11.02 16.08 10.9 16.05 10.78 15.98C10.45 15.78 10.34 15.35 10.54 15.01L17.07 4.1C17.27 3.76 17.7 3.66 18.04 3.86C18.37 4.05 18.48 4.49 18.28 4.82L11.75 15.74C11.62 15.96 11.38 16.08 11.14 16.08Z" fill="#444444" fillOpacity="1.000000" fillRule="evenodd"/>
		<path id="Vector" d="M27.18 16.08C26.94 16.08 26.71 15.96 26.57 15.74L20.1 4.92C19.9 4.59 20.01 4.15 20.35 3.95C20.68 3.75 21.11 3.86 21.31 4.2L27.79 15.01C27.98 15.35 27.88 15.78 27.54 15.98C27.43 16.05 27.3 16.08 27.18 16.08Z" fill="#444444" fillOpacity="1.000000" fillRule="evenodd"/>
		<path id="Vector" d="M19.16 5.58C17.64 5.58 16.4 4.34 16.4 2.82C16.4 1.3 17.64 0.06 19.16 0.06C20.69 0.06 21.93 1.3 21.93 2.82C21.93 4.34 20.69 5.58 19.16 5.58ZM19.16 1.47C18.42 1.47 17.81 2.07 17.81 2.82C17.81 3.57 18.42 4.17 19.16 4.17C19.91 4.17 20.51 3.57 20.51 2.82C20.51 2.07 19.91 1.47 19.16 1.47Z" fill="#444444" fillOpacity="1.000000" fillRule="evenodd"/>
		<path id="Vector" d="M13.64 22.39C13.64 23.55 13.43 24.43 13.02 25.02C12.61 25.61 12.01 25.9 11.22 25.9C10.43 25.9 9.82 25.61 9.4 25.02C8.98 24.43 8.77 23.55 8.77 22.39C8.77 21.24 8.98 20.37 9.4 19.78C9.82 19.18 10.42 18.89 11.21 18.89C12 18.89 12.6 19.18 13.01 19.77C13.43 20.36 13.64 21.24 13.64 22.39ZM12.46 22.4C12.46 21.59 12.37 20.96 12.18 20.5C12 20.04 11.67 19.81 11.2 19.81C10.74 19.81 10.41 20.04 10.23 20.5C10.04 20.96 9.95 21.6 9.95 22.4C9.95 23.18 10.04 23.81 10.22 24.28C10.4 24.75 10.74 24.99 11.22 24.99C11.67 24.99 11.99 24.76 12.18 24.31C12.37 23.85 12.46 23.21 12.46 22.4Z" fill="#F1F1F1" fillOpacity="1.000000" fillRule="evenodd"/>
		<path id="Vector" d="M14.71 19.01L16.39 19.01C16.92 19.01 17.36 19.06 17.69 19.16C18.02 19.26 18.31 19.49 18.53 19.83C18.76 20.18 18.88 20.59 18.88 21.08C18.88 21.53 18.78 21.91 18.6 22.23C18.41 22.55 18.16 22.78 17.85 22.92C17.54 23.07 17.06 23.14 16.41 23.14L15.83 23.14L15.83 25.78L14.71 25.78L14.71 19.01ZM15.83 19.88L15.83 22.24L16.39 22.24C16.88 22.24 17.23 22.15 17.42 21.96C17.61 21.78 17.7 21.47 17.7 21.06C17.7 20.75 17.64 20.5 17.51 20.3C17.39 20.11 17.25 19.99 17.1 19.95C16.95 19.9 16.71 19.88 16.39 19.88L15.83 19.88Z" fill="#F1F1F1" fillOpacity="1.000000" fillRule="evenodd"/>
		<path id="Vector" d="M19.73 19.01L23.61 19.01L23.61 19.91L20.85 19.91L20.85 21.84L23.01 21.84L23.01 22.74L20.85 22.74L20.85 24.86L23.65 24.86L23.65 25.78L19.73 25.78L19.73 19.01Z" fill="#F1F1F1" fillOpacity="1.000000" fillRule="evenodd"/>
		<path id="Vector" d="M24.37 19.01L25.69 19.01L27.91 23.89L27.91 19.01L28.85 19.01L28.85 25.78L27.8 25.78L25.3 20.36L25.3 25.78L24.37 25.78L24.37 19.01Z" fill="#F1F1F1" fillOpacity="1.000000" fillRule="evenodd"/>
		<path id="Vector" d="M36.21 29.83L2.11 29.83C0.94 29.83 0 28.88 0 27.72L0 17C0 15.83 0.94 14.88 2.11 14.88L36.21 14.88C37.38 14.88 38.33 15.83 38.33 17L38.33 27.72C38.33 28.88 37.38 29.83 36.21 29.83ZM2.11 16.29C1.72 16.29 1.41 16.61 1.41 17L1.41 27.72C1.41 28.11 1.72 28.42 2.11 28.42L36.21 28.42C36.6 28.42 36.92 28.11 36.92 27.72L36.92 17C36.92 16.61 36.6 16.29 36.21 16.29L2.11 16.29Z" fill="url(#paint_linear_9_2338_0)" fillOpacity="1.000000" fillRule="evenodd"/>
	</g>
</svg>

  )
}
